import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './services/shared/shared.module';
import { LayoutModule } from '@angular/cdk/layout';
import { NgcCookieConsentModule, NgcCookieConsentConfig } from 'ngx-cookieconsent';
import { environment } from '../environments/environment';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { FirestoreService } from './services/firesore.service';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { MatSelectCountryModule } from '@angular-material-extensions/select-country';
import { GlobalErrorHandler } from './services/error.handler';
import { ScriptLoaderModule } from 'ngx-script-loader';
import { CustomScriptDirective } from './directives/custom-script.directive';
import { OfferReqComponent } from './offer-req/offer-req.component';

const cookieConfig:NgcCookieConsentConfig = {
  
    "cookie": {
      "domain": "touristadvisor.ro"
    },
    "position": "bottom-left",
    "theme": "classic",
    "palette": {
      "popup": {
        "background": "#fbfbfb",
        "text": "#000000",
        "link": "#000000"
      },
      "button": {
        "background": "#dbdbd8",
        "text": "#000000",
        "border": "transparent"
      }
    },
    "type": "info",
    "content": {
      "message": "Folosim cookie-uri pentru a ne asigura că aveți cea mai bună experiență pe site-ul nostru.",
      "dismiss": "Am Inteles!",
      "deny": "Refuse cookies",
      "link": "Politica Cookies",
      "href": "https://touristadvisor.ro/page/spg/cookies",
      "policy": "Cookie Policy"
    },
    dismissOnScroll: 200,
  
};

@NgModule({
  declarations: [
    AppComponent,
    OfferReqComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireStorageModule,
    LayoutModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    NgcCookieConsentModule.forRoot(cookieConfig), 
    MatSelectCountryModule.forRoot('en'),
    HttpClientModule,
    HttpClientJsonpModule,
    ScriptLoaderModule
    
  ],
  providers: [
    FirestoreService,
    {provide: ErrorHandler, useClass: GlobalErrorHandler}],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
]
})
export class AppModule { }
