import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatGridListModule } from '@angular/material/grid-list';
import { RouterModule } from '@angular/router';
import { MatCardModule } from '@angular/material/card';
import { CarouselComponent } from '../../home/carousel/carousel.component';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { BarRatingModule } from 'ngx-bar-rating';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ContentLoaderModule } from '@ngneat/content-loader';
import { MatExpansionModule } from '@angular/material/expansion';
import {MatRadioModule} from '@angular/material/radio';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule} from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {MatTabsModule} from '@angular/material/tabs';
import {MatMenuModule} from '@angular/material/menu';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { CustomScriptDirective } from 'src/app/directives/custom-script.directive';


@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    CarouselComponent,
    CustomScriptDirective
  ],
  imports: [
    RouterModule,
    CommonModule,
    MatIconModule,
    MatToolbarModule,
    MatButtonModule,
    MatGridListModule,
    MatCardModule,
    MatSelectModule,
    FormsModule, 
    ReactiveFormsModule,
    MatDialogModule,
    BarRatingModule,
    MatTooltipModule,
    MatProgressBarModule,
    ContentLoaderModule,
    MatExpansionModule,
    MatRadioModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule, 
    MatInputModule,
    MatCheckboxModule,
    MatTabsModule,
    MatMenuModule,
    MatSnackBarModule

  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    CarouselComponent,
    MatIconModule,
    MatToolbarModule,
    MatButtonModule,
    MatGridListModule,
    MatCardModule,
    MatSelectModule,
    FormsModule, 
    ReactiveFormsModule,
    MatDialogModule,
    BarRatingModule,
    MatTooltipModule,
    MatProgressBarModule,
    ContentLoaderModule,
    MatExpansionModule,
    MatRadioModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule, 
    MatInputModule,
    MatCheckboxModule,
    MatTabsModule,
    MatMenuModule,
    MatSnackBarModule,
    CustomScriptDirective
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class SharedModule { }
