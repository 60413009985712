import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { trigger, transition, useAnimation } from "@angular/animations";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";

@Component({
  selector: "carousel",
  templateUrl: "./carousel.component.html",
  styleUrls: ["./carousel.component.scss"],
})

export class CarouselComponent implements OnInit{
  sizeSmall = false;
  @Input() slides: any;

  constructor(private responsive: BreakpointObserver) {}

  currentSlide = 0;

  ngOnInit(): void {
    this.responsive.observe([Breakpoints.XSmall,Breakpoints.Small])
    .subscribe(result => {
      this.sizeSmall = result.matches;
    });
  }

  onPreviousClick() {
    const previous = this.currentSlide - 1;
    this.currentSlide = previous < 0 ? this.slides.length - 1 : previous;
    console.log("previous clicked, new current slide is: ", this.currentSlide);
  }

  onNextClick() {
    const next = this.currentSlide + 1;
    this.currentSlide = next === this.slides.length ? 0 : next;
    console.log("next clicked, new current slide is: ", this.currentSlide);
  }

  goToBlog(){
    window.open('https://blog.touristadvisor.ro/', "_self");
  }

}
