import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';

export interface Tile {
  title: string;
  cols: number;
  rows: number;
  links: any[];
  img: string;
}

@Component({
  selector: 'site-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  sizeSmall = false;
  year:number = 0;
  footerButtons: any[]=[];

  tiles: Tile[] = [

    {title:'',links: [
      {text: 'Acte Necesare',  link: 'page/spg/acte-calatorie', icon: 'chevron_right', router: true},
      {text: 'Documente Utile',  link: 'page/documente-utile', icon: 'chevron_right', router: true},
      {text: 'Contract Turistic',  link: 'https://firebasestorage.googleapis.com/v0/b/tourist-advisor-agency.appspot.com/o/public_docs%2Fdocumente-utile%2F1.%20Contractul%20de%20comercializare%20a%20pachetelor%20de%20servicii%20turistice.pdf?alt=media&token=2f4a966e-014f-4709-99a5-15ae199f966c', icon: 'chevron_right', router: false}
    ],cols: 1, rows: 1, img: ''},
    {title:'',links: [
      {text: 'Sfaturi',  link: 'page/informatii-utile', icon: 'chevron_right', router: true},
      {text: 'Despre Noi',  link: 'page/spg/despre', icon: 'chevron_right', router: true},
      {text: 'Blog',  link: 'https://blog.touristadvisor.ro/', icon: 'chevron_right', router: false}
    ],cols: 1, rows: 1, img: ''},
    {title:'',links: [
      {text: 'Cariere',  link: 'page/spg/cariere', icon: 'chevron_right', router: true},
      {text: 'Agentii',  link: 'page/agentii-partenere', icon: 'chevron_right', router: true},
      {text: 'B2B',  link: 'https://b2b.touristadvisor.ro/', icon: 'chevron_right', router: false}
    ],cols: 1, rows: 1, img: ''},
  ];

  constructor(private responsive: BreakpointObserver) { }

  ngOnInit(): void {

    this.responsive.observe([Breakpoints.XSmall])
    .subscribe(result => {
      this.sizeSmall = result.matches;
    })

    const d = new Date();
    this.year = d.getFullYear();
    
    this.footerButtons = [
      {text: 'Acasa', link: '/', icon: 'chevron_right'},
      {text: 'Termeni & Conditii', link: 'page/spg/terms', icon: 'chevron_right'},
      {text: 'Confidentialitate',  link: 'page/spg/gdpr', icon: 'chevron_right'},
      {text: 'Cookies',  link: 'page/spg/cookies', icon: 'chevron_right'},
      {text: 'Contact',  link: 'page/contact', icon: 'chevron_right'}
    ];
  }

  openUrl(url: string){
    window.open(url, '_blank');
  }

  openWhatsApp(){
    const baseUrl = 'https://wa.me/+40752555111';
    const text = '?text=Buna%20ziua%2C%0Adoresc%20o%20oferta%2A%20de%20sejur%20pentru%20insula%20Menorca.%0A%0A%2ADetaliile%20Solicitarii%3A%20%0A-%20numar%20adulti%3A%20%0A-%20numar%20copii%3A%0A-%20varstele%20copiilor%3A%20%0A-%20perioada%20sejurului%3A';
    window.open(baseUrl+text, '_blank');
  }

}
