import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

interface Room {
  adults: number;
  children: number;
  childAges: { age: number }[];
}

interface CountResult {
  adults: number;
  children: number;
  infants: number;
}

@Component({
    selector: 'people-dialog',
    templateUrl: 'people.dialog.html',
    styleUrls: ['people.dialog.scss']
  })


  export class PeopleSelectorComponent implements OnInit{

    camereNr: number = 1;
    sizeSmall = false;
    roomForm: FormGroup;
    showErrorVarsta = false;

    ages = [
      0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17
    ];
    maxChildren = [
      0, 1, 2, 3
    ];

   
    constructor(
      private responsive: BreakpointObserver,
      private formBuilder: FormBuilder,
      private fb: FormBuilder,
      public dialogRef: MatDialogRef<PeopleSelectorComponent>,
      @Inject(MAT_DIALOG_DATA) public data:any){
      console.log(this.data)
    }

    getControls(name:string) {
      return (this.roomForm.get(name) as FormArray).controls;
    }

    rooms(): FormArray {
      return this.roomForm.get("rooms") as FormArray
    }

    childAges(empIndex:number) : FormArray {
      return this.rooms().at(empIndex).get("childAges") as FormArray
    }

    ngOnInit(): void {
      this.responsive.observe([Breakpoints.XSmall,Breakpoints.Small])
      .subscribe(result => {
        this.sizeSmall = result.matches;
      });

      this.roomForm = this.formBuilder.group({
        rooms: this.formBuilder.array([
          this.initRoom()
        ])
      });
    }
  
    initRoom(): FormGroup {
      return this.formBuilder.group({
        adults: new FormControl(2, Validators.required),
        children: new FormControl(0, Validators.required),
        childAges: this.formBuilder.array([]) // Child ages will be added dynamically based on the number of children
      });
    }

    isMacOS(): boolean {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /macintosh/.test(userAgent) && !/iphone|ipad/.test(userAgent);
    }
  
  
     addChildAges(index: number, numChildren: any) {
      console.log( index, numChildren)
      const roomsControl = this.roomForm.get('rooms') as FormArray;
      const roomControl = roomsControl.at(index) as FormGroup;
      if (roomControl) {
      const childAgesControl = roomControl.get('childAges') as FormArray;
      childAgesControl.clear() // Clear existing child ages
      //console.log('hit'); 

      const numChildGroups = Array.from({ length: numChildren }).map(() =>
        this.formBuilder.group({ age: [null, Validators.required] })
      );

      childAgesControl.clear(); // Clear existing child ages
      numChildGroups.forEach((group) => {
        childAgesControl.push(group);
      });
       
      }
    }

  
    addRoom(): void {
      if(this.camereNr < 5){
        const roomsControl = this.roomForm.get('rooms') as FormArray;
        roomsControl.push(this.initRoom());
        this.camereNr++
      }
    }
  
    removeRoom(): void {
      if(this.camereNr > 1){
        const roomsControl = this.roomForm.get('rooms') as FormArray;
        roomsControl.removeAt(-1);
        this.camereNr--
      }
    }

    onSubmit(): void {
       if (this.roomForm.valid) {
        // Do something with the form data
        console.log(this.roomForm.value);
       
        const data = {
          roomConfig: this.roomConfigNoInfants(this.roomForm.value),
          peopleConfig: this.countPeople(this.roomForm.value),
          peopleConfigPayment: this.countPeoplePayment(this.roomForm.value)
        }
        console.log(data);

        this.dialogRef.close(data);

       } else {
        alert('Va rugam selectati varsta copiilor!');
        this.showErrorVarsta = true;
       }
    }




    noTimes(nr:number): any[]{
      //alert('it works');
      //return Array(nr).fill(0).map((x,i)=>i);
      const result: number[] = [];
      for (let i = 0; i < nr; i++) {
        result.push(i);
      }
      return result;
    }

    roomConfigNoInfants(data: { rooms: Room[] }): string[] {
      const results: string[] = [];

      data.rooms.forEach(room => {
          const adults = room.adults;
  
          const infants = room.childAges.filter(childAge => childAge.age < 2).length;
          const nonInfants = room.children - infants;
  
          results.push(`${adults}+${nonInfants}`);
      });
  
      return results;
    }

    countPeoplePayment(data: { rooms: Room[] }): CountResult {
      let adultsCount = 0;
      let childrenCount = 0;
      let infantsCount = 0;
  
      data.rooms.forEach(room => {
          adultsCount += room.adults;
  
          room.childAges.forEach(childAge => {
            if (childAge.age >= 12) {
                adultsCount++;
            } else if (childAge.age < 2) {
                infantsCount++;
            } else {
                childrenCount++;
            }
        });

      });
  
      return { adults: adultsCount, children: childrenCount, infants: infantsCount };
    }

    countPeople(data: { rooms: Room[] }): CountResult {
      let adultsCount = 0;
      let childrenCount = 0;
      let infantsCount = 0;
  
      data.rooms.forEach(room => {
          adultsCount += room.adults;
  
          room.childAges.forEach(childAge => {
              if (childAge.age < 2) {
                  infantsCount++;
              } else {
                  childrenCount++;
              }
          });
      });
  
      return { adults: adultsCount, children: childrenCount, infants: infantsCount };
  }


  
  
  
  }