import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import {Breakpoints} from '@angular/cdk/layout';

@Component({
  selector: 'site-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {

  sizeSmall = false;

  constructor(private responsive: BreakpointObserver) { }

  ngOnInit(): void {
    this.responsive.observe([Breakpoints.XSmall,Breakpoints.Small])
    .subscribe(result => {
      this.sizeSmall = result.matches;
    });
  }

  phoneAssistance(url: string){
    window.open(url, "_self");
  }
  goToBlog(){
    window.open('https://blog.touristadvisor.ro/', "_blank");
  }

  openSocial(platform:string){
    if(platform === 'facebook'){
      window.open('https://www.facebook.com/touristadvisor.ro', "_blank");
    } else if(platform === 'instagram'){
      window.open('https://www.instagram.com/tourist.advisor.ro/', "_blank");
    } else if (platform === 'tiktok'){
      window.open('https://www.tiktok.com/@touristadvisor', "_blank");
    } else {
      return
    }
  }

}
