<div style="padding:24px">
  <div mat-dialog-title>
    <mat-icon aria-hidden="false" aria-label="report" fontIcon="report"></mat-icon>
</div>

<mat-dialog-content class="mat-typography" *ngIf="data">
 

<p style="font-weight: bold; font-size:14pt">Selectează mai întâi:<br></p>
<p *ngIf="data.plecare"><mat-icon aria-hidden="false" aria-label="flight_takeoff" fontIcon="flight_takeoff"></mat-icon> Localitatea de Plecare</p>
<p *ngIf="data.destinatie"><mat-icon aria-hidden="false" aria-label="flight_land" fontIcon="flight_land"></mat-icon> Destinația Sejurului</p>
<p *ngIf="data.perioada"><mat-icon aria-hidden="false" aria-label="date_range" fontIcon="date_range"></mat-icon> Perioada Sejurului</p>


</mat-dialog-content>


<mat-dialog-actions align="end">
  <button mat-raised-button [mat-dialog-close]="data" cdkFocusInitial class="custom-btn">OK</button>
</mat-dialog-actions>
</div>
