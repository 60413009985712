import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home/home.component';

const mainTitle = 'Tourist Advisor';

const routes: Routes = [

 // Landing routes
 {
     path: '',
     children   : [
         {path: '', loadChildren: () => import('../app/home/home.module').then(m => m.HomeModule),data: { title: mainTitle }},
         {path: 'destinations', loadChildren: () => import('../app/destinations/destinations.module').then(m => m.DestinationsModule),data: { title: mainTitle }},
         {path: 'offer', loadChildren: () => import('../app/offer/offer.module').then(m => m.OfferModule),data: { title: mainTitle }},
         {path: 'page', loadChildren: () => import('../app/pages/pages.module').then(m => m.PagesModule),data: { title: mainTitle }}
     ]
 },

 // Product routes
 { path: 'order', loadChildren: () => import('./reservation/reservation.module').then(m => m.ReservationModule),data: { title: mainTitle } },
 { path: 'checkout', loadChildren: () => import('./stripe-checkout/checkout.module').then(m => m.CheckoutModule),data: { title: mainTitle } },
  // fix for documents beauty 
 {path: 'documente-utile', pathMatch: 'full', redirectTo: 'page/documente-utile'},

 // 404
 {path: '**', component: HomeComponent,data: { title: mainTitle }},

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    initialNavigation: 'enabledBlocking'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
