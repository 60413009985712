import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {Component, Inject, OnInit} from '@angular/core';
import { AngularFirestore, DocumentReference } from '@angular/fire/compat/firestore';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DateTime } from 'luxon';

@Component({
    selector: 'offer-popup',
    templateUrl: 'offer.component.html',
    styleUrls: ['offer.component.scss']
  })
  export class OfferPopupDialog implements OnInit {
    sizeSmall = false;
    startVacation = 0;
    endVacation = 0;
    OfferRequest: FormGroup;
    offerRequestId ='';

    constructor(
        private fb: FormBuilder,
        public dialogRef: MatDialogRef<OfferPopupDialog>,
        @Inject(MAT_DIALOG_DATA) public data:any,
        private afs: AngularFirestore,
        private responsive: BreakpointObserver) {
        //console.log(data)
    }

    closeDialog(){
        this.dialogRef.close();
    }



    ngOnInit(): void {
        this.responsive.observe([Breakpoints.XSmall,Breakpoints.Small])
        .subscribe(result => {
            this.sizeSmall = result.matches;
        });
        this.OfferRequest = this.fb.group({
            name: ['', Validators.required],
            email: ['', Validators.required],
            phone: ['', Validators.required],
            origin: ['', Validators.required],
            destination: ['', Validators.required],
            period: ['', Validators.required],
            nr_rooms: '',
            nr_ad: '',
            nr_ch: '',
            ages: '',
            cat_hotel: '',
            meals: '',
        });
    }

    send(){
        console.log(this.OfferRequest.value)
        this.afs.collection<any>('offers').add(this.OfferRequest.value).then(docRef => {
            //console.log((docRef) ? (<DocumentReference>docRef).id : 'void') // docRef of type void | DocumentReference
            this.offerRequestId = (<DocumentReference>docRef).id;
            console.log(this.offerRequestId);
          })
    }

      
  }
  