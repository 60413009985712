
<mat-dialog-content class="mat-typography" *ngIf="data" style="margin: 25px 10px;">

    <mat-icon aria-hidden="false" aria-label="groups" fontIcon="groups"></mat-icon>

    <div class="selector adulti">
        <div class="button-sel">
            <button mat-icon-button aria-label="add" (click)="addRoom()">
                <mat-icon>add</mat-icon>
            </button>
        </div> 
        <div class="tittle-sel">
            <span><strong>{{camereNr}}</strong> {{camereNr > 1 ? "camere" : "camera"}}</span>
        </div>
        <div class="button-sel">
            <button mat-icon-button aria-label="remove" (click)="removeRoom()">
                <mat-icon>remove</mat-icon>
            </button>
        </div>
    </div>
    
    

    <form *ngIf="roomForm" [formGroup]="roomForm" (ngSubmit)="onSubmit()">
        <div formArrayName="rooms" class="rooms-list">
          <div *ngFor="let room of rooms().controls; let i = index" [formGroupName]="i" class="room-item" [ngClass]="{'second-item': i > 0}">
            <p>Camera {{ i + 1 }}</p>
            <mat-grid-list cols="10" [rowHeight]="sizeSmall ? '80px' : '80px'" >
                <mat-grid-tile [colspan]="sizeSmall ? '2' : '2'" class="pushDownPlan">
                    <mat-icon aria-hidden="false" aria-label="person" fontIcon="person" class="custom-font"></mat-icon>

                </mat-grid-tile>
                <mat-grid-tile [colspan]="sizeSmall ? '3' : '3'" class="pushDownPlan">
                    <mat-form-field appearance="outline" style="width: 80%;">
                        <mat-label>Adulti</mat-label>
                        <mat-select formControlName="adults">
                          <!-- <mat-option [value]="1">1</mat-option> -->
                          <mat-option [value]="2">2</mat-option>
                          <mat-option [value]="3">3</mat-option>
                          <mat-option [value]="4">4</mat-option>
                        </mat-select>
                      </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile [colspan]="sizeSmall ? '2' : '2'" class="pushDownPlan">
                    <mat-icon aria-hidden="false" aria-label="man" fontIcon="man" class="custom-font"></mat-icon>
                </mat-grid-tile>
                <mat-grid-tile [colspan]="sizeSmall ? '3' : '3'" class="pushDownPlan">
                    <mat-form-field appearance="outline" style="width: 80%;">
                        <mat-label>Copii</mat-label>
                        <mat-select formControlName="children" (selectionChange)="addChildAges(i, $event.value)">
                          <mat-option *ngFor="let item of maxChildren; let c = index" [value]="c">{{c}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                </mat-grid-tile>
            </mat-grid-list>
              
            <div formArrayName="childAges">
                <mat-grid-list cols="6" [rowHeight]="sizeSmall ? '80px' : '80px'" *ngIf="childAges(i).controls.length > 0">
                <div *ngFor="let item of childAges(i).controls; let j = index" style="width: 100%;">

                        
                        <mat-grid-tile [colspan]="sizeSmall ? '2' : '2'" class="pushDownPlan">
                            <div [formGroupName]="j" style="width: 100%;" class="custom-select">

                                <!-- <div *ngIf="!isMacOS">
                                    <mat-form-field appearance="outline" style=" width: 100%; ">
                                        <mat-label>Varsta {{ j + 1 }}</mat-label>
                                        <mat-select formControlName="age">
                                            <mat-option *ngFor="let item of ages; let u = index" [value]="u">{{u}}</mat-option>
                                            
                                        </mat-select>
                                    </mat-form-field>
                                </div> -->
                                
                                    <label for="age{{ j + 1 }}">Varsta {{ j + 1 }}</label>
                                    <select id="age{{ j + 1 }}" formControlName="age">
                                        <option *ngFor="let item of ages; let u = index" [value]="u">{{u}}</option>
                                    </select>
                            </div>
                            </mat-grid-tile>
                   

                </div>
                <p class="custom-hint" *ngIf="showErrorVarsta">Va rugam selectati varsta copiilor!</p>

                </mat-grid-list>
            </div>
                
            </div>
          </div>
        <button mat-raised-button cdkFocusInitial class="custom-btn" type="submit">Continua</button>

      </form>

</mat-dialog-content> 

