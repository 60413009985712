<header class="top">
  <section class="top-header right-aligned">
    <button mat-button class="example-icon favorite-icon" routerLink="/" aria-label="Acasa">
      ACASA
    </button>
    <button mat-button class="example-icon favorite-icon" aria-label="Informații Utile" routerLink="page/informatii-utile">
      INFORMATII UTILE
    </button>
    <button mat-button class="example-icon favorite-icon" routerLink="/page/spg/despre" aria-label="Despre">
      DESPRE
    </button>
    <button mat-button class="example-icon favorite-icon" routerLink="/page/contact" aria-label="Contact">
      CONTACT
    </button>
  </section>
</header>

<header>
   
    <section class="main-navigation">
        <mat-toolbar>
            
            <img src="assets/loco-text-site.png" width="auto" height="64" routerLink="/"class="mian-logo" alt="Tourist Advisor">

            <span style="flex: 1 1 auto"></span>

            <button mat-button class="example-icon favorite-icon" *ngIf="!sizeSmall" routerLink="page/agentii-partenere" aria-label="Blog">
              <mat-icon>where_to_vote</mat-icon>Agentii Partenere
            </button>

            <button mat-button class="example-icon favorite-icon" *ngIf="!sizeSmall" (click)="goToBlog()" aria-label="Blog">
              <mat-icon>rss_feed</mat-icon> Blog
            </button>

            <button mat-button class="example-icon favorite-icon" *ngIf="!sizeSmall" (click)="phoneAssistance('tel:+40752555111')" aria-label="Mobile Menu">
              <mat-icon>phone</mat-icon> 0752 555 111
            </button>

            <button mat-icon-button [matMenuTriggerFor]="menu" *ngIf="sizeSmall" class="example-icon favorite-icon" aria-label="Mobile Menu">
              <mat-icon>more_vert</mat-icon>
            </button>

            <mat-menu #menu="matMenu"> 
              <button mat-menu-item routerLink="destinations/visit/menorca">
                <mat-icon>house</mat-icon>
                <span>Acasa</span>
              </button>
              <button mat-menu-item (click)="phoneAssistance('tel:+40752555111')">
                <mat-icon>phone</mat-icon>
                <span>0752.555.111</span>
              </button>
              <button mat-menu-item routerLink="page/agentii-partenere">
                <mat-icon>where_to_vote</mat-icon>
                <span>Agentii Partenere</span>
              </button>
              <button mat-menu-item routerLink="/page/spg/despre">
                <mat-icon>account_circle</mat-icon>
                <span>Despre</span>
              </button>
              <button mat-menu-item (click)="goToBlog()">
                <mat-icon>rss_feed</mat-icon>
                <span>Blog</span>
              </button>
              <button mat-menu-item routerLink="page/informatii-utile">
                <mat-icon>assistant</mat-icon>
                <span>Informații Utile</span>
              </button>
              <button mat-menu-item routerLink="/page/contact">
                <mat-icon>contact_support</mat-icon>
                <span>Contact</span>
              </button>
            </mat-menu>

          </mat-toolbar>
    </section>
</header>
