<mat-dialog-content class="mat-typography newsletter-form" style="margin:1em 0;">

    <p style="font-size: 16pt;font-weight: 100;text-align: center;margin-bottom: 2em;" *ngIf="message.text != ''">{{message.text}}</p>

    <form [formGroup]="newsletterFormGroup" (ngSubmit)="submit()" *ngIf="message.subscribed === false">

        <mat-form-field appearance="fill">
            <mat-label>Nume & Prenume</mat-label>
            <input matInput placeholder="Andrei Popescu" formControlName="nameControl">
            <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>Adresa de e-mail</mat-label>
            <input matInput placeholder="exemplu@domeniu.com" formControlName="emailControl">
            <mat-icon matSuffix>mail</mat-icon>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>Numar de telefon</mat-label>
            <input matInput placeholder="0744.444.444" formControlName="phoneControl">
            <mat-icon matSuffix>phone_iphone</mat-icon>
        </mat-form-field>
        
        <button mat-raised-button class="custom-btn" type="submit" [disabled]="!newsletterFormGroup.valid">
            <span style="color:white; font-weight: 100;">Aboneaza-te la <strong>newsletter</strong></span>
        </button>

    </form>

</mat-dialog-content>
