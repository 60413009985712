import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MailchimpService {

  constructor(private http:HttpClient) { }

  addEmail(body: any){
    const url = 'https://us-central1-tourist-advisor-agency.cloudfunctions.net/api/newsletter-add';

    let headers = new HttpHeaders();
    headers = headers.append('Content-Type','application/json');
    headers = headers.append('Access-Control-Allow-Origin','*');

    return this.http.post(url,body,{headers:headers});

  }
}
