import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DateTime } from "luxon";
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FirestoreService } from 'src/app/services/firesore.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { MailchimpService } from 'src/app/services/mailchimp.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { FlightDetailsDialog } from 'src/app/offer/flight-hotel/flight-details/flight-details.component';
import { OfferPopupDialog } from '../offer-popup/offer.component';
import { PeopleSelectorComponent } from './people.dialog';

interface MailChimpResponse {
  result: string;
  msg: string;
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  
  sizeSmall = false;

  origin = { name:'', call:''};
  destination =  { name:'', call:''};
  period = { start:'', end:'' };


  people = {
    adults: 2,
    children: 0,
    infants:0
  };
  roomConfig=['2+0'];
  peopleConfigPayment = {
    adults: 2,
    children: 0,
    infants:0
  }

  DepartureSelect: FormGroup;
  ArrivalSelect: FormGroup;
  PeriodSelect: FormGroup;

  periods: Observable<any[]>;
  departures: Observable<any[]>;
  arrivals: Observable<any[]>;
  carousell: Observable<any[]>;
  banners: Observable<any[]>;

  constructor(
    public fs: FirestoreService,
    public router: Router,
    private firestore: AngularFirestore,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private responsive: BreakpointObserver) { 
  
  }

  getCarousell(){
    this.carousell = this.fs.getCollection('public/home/carousel');
  }

  getBanners(){
    this.banners = this.fs.getCollection('public/home/banners');
  }

  getDeparture(){
    this.departures = this.fs.getCollection('destinations');
  }

  getArrival(departure:string){
    this.destination =  { name:'', call:''};
    const dbCall = 'destinations/'+departure+'/arrivals'
    this.arrivals = this.fs.getCollection(dbCall, 'order');
    
  }

  getPeriods(departure:string,arrival:string){
    this.period = { start:'', end:'' };
    const dbCall = 'periods/'+departure+'-'+arrival+'/periods'
    this.periods = this.fs.getCollection(dbCall,'start');
  }

  ngOnInit(): void {

    this.responsive.observe([Breakpoints.XSmall,Breakpoints.Small])
    .subscribe(result => {
      this.sizeSmall = result.matches;
    });
    
    this.getCarousell();

    setTimeout(() => {
      this.getBanners();
      // this.initInstagramFeed(1000);
    }, 100);


    this.DepartureSelect = this.fb.group({
      name: [],
    });
    this.ArrivalSelect = this.fb.group({
      name: [],
    });

    this.PeriodSelect = this.fb.group({
      start: [],
    });

    this.getDeparture();
    //this.verifyBirthdate(6,4);
  }

  initInstagramFeed(milisec: number){
    setTimeout(() => {
      (window as any).beholdWidgets.initialize();
    }, milisec);
  }

  returnPeopleSelected(): string {
    let ppl = '';

    if(this.people.adults > 0){
      const adultName = this.people.adults > 1 ? "adulți" : "adult";
      ppl = this.people.adults + adultName;
    }

    if(this.people.children > 0){
      const childrenName = this.people.children > 1 ? "copii" : "copil";
      ppl = ppl + ' ' + this.people.children + childrenName;
    }

    if(this.people.infants > 0){
      const infantsName = this.people.infants > 1 ? "nou născuți" : "nou născut";
      ppl = ppl + ' ' + this.people.infants + infantsName;
    }

    return ppl;
  }


  // async verifyBirthdate(m:number,d:number){
  //   const call = await new ZodiacApiService();
  //   const zodie = call.sign(m,d);
  //   console.log(zodie);
  // }

  changeDeparture(ev:any){
    this.origin = ev.value;
    this.getArrival(ev.value.call);
    //console.log(ev.value.call);
  }  
  
  changeArrival(ev:any){
    this.destination = ev.value;
    this.getPeriods(this.origin.call,this.destination.call);
    //console.log(this.destination)
    if(
      this.destination.call === 'HER' || 
      this.destination.call === 'HRG' || 
      this.destination.call === 'PUJ'
      ){
      this.openOfferPopup();
    }
  }

  changePeriod(ev:any){
    this.period = ev.value;
  }

  returnDate(input:any){
    let newDate;
    if(input != null){
      newDate = input.toDate();
    } else {
      return
    }
    const formatDate = DateTime.fromJSDate(newDate).toFormat('dd MMM yyyy', { locale: "ro" });
    return formatDate;
  }

  openPeopleSelector() {
    const dialogRef = this.dialog.open(PeopleSelectorComponent,{
      maxWidth: '90vw', // Set maximum width
      maxHeight: '90vh', // Set maximum height
      height: 'auto', // Set initial height to auto
      width: '430px',
      panelClass:'ps-class',
      data: this.people,
    });
    dialogRef.afterClosed().subscribe(result => {
      //console.log(result);
      this.people = result.peopleConfig;
      this.roomConfig = result.roomConfig;
      this.peopleConfigPayment = result.peopleConfigPayment;
    });
  }

  openSelectError(settings: any) {
    const dialogRef = this.dialog.open(SelectError,{
      //height: '300px',
      width: '300px',
      panelClass:'ps-class',
      data: settings,
    });
    dialogRef.afterClosed().subscribe(result => {
      //console.log(result);
      //this.people = result;
    });
  }

  openNewsletter() {
    const dialogRef = this.dialog.open(NewsletterDialog,{
      //height: '300px',
      width: '650px',
      panelClass:'ps-class'
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  openOffer(){

    if( this.origin.call === '' || this.destination.call === '' || this.period.start === ''){
      this.searchClickEvent();
    } else {

      localStorage.removeItem('trip');
      localStorage.removeItem('hotel');
      
      const peopleCount = this.people.adults+ this.people.children+this.people.infants;
      const offerUrl = '/offer/generated/'+this.origin.call+'-'+this.destination.call+'-ppl-'+peopleCount+'-'+(this.period.start as any).seconds+'-'+(this.period.end as any).seconds;

      const trip = {
        url: offerUrl,
        departure: this.origin,
        arrival: this.destination,
        period: this.period,
        people: this.people,
        peopleConfigPayment: this.peopleConfigPayment,
        roomConfig: this.roomConfig,
      }

      const striTrip = JSON.stringify(trip);
      localStorage.setItem('trip', striTrip);
      this.router.navigateByUrl(offerUrl, { state: trip });
    }
  }

  destinationClickEvent(ev: any){
    //console.log(ev)
    if (ev === undefined){
      this.openSelectError({
        plecare:true,
        destinatie:false,
        perioada:false
      });
    }
  }

  vacationPeriodClickEvent(ev: any){
    //console.log(ev)
    if (ev === undefined){
      this.openSelectError({
        plecare:true,
        destinatie:true,
        perioada:false
      });
    }
  }

  searchClickEvent(){
    this.openSelectError({
      plecare:true,
      destinatie:true,
      perioada:true
    });
  }

  openOfferPopup() {
    const popup = this.dialog.open(OfferPopupDialog, {
        panelClass:'dialog-flights',
        width:'100%',
        maxWidth:'750px',
        autoFocus: false,
        data: {
          origin: this.origin,
          destination: this.destination
        },
    });
    popup.afterClosed().subscribe(result => {
      console.log(result);
      this.destination =  { name:'', call:''};
      this.ArrivalSelect.reset();
    });
  }

}



@Component({
  selector: 'select-error',
  templateUrl: 'select-error.html',
  styles:[
    `
    .mat-dialog-title { height: 30px; text-align: center;} 
    .mat-dialog-title  .mat-icon {font-size: 50px; height: 50px;width: 50px;text-align: center;}
    .aligned-with-icon { position: relative; top: -12px; }
    .mat-typography .mat-icon {line-height: 32px; font-size: 21px;}
    `
  ]
})
export class SelectError {
 
  constructor(@Inject(MAT_DIALOG_DATA) public data:any){
    //console.log(this.data)
  }



}



@Component({
  selector: 'newsletter-dialog',
  templateUrl: 'newsletter.dialog.html',
  styles:[
    `
    .newsletter-form {
        .mat-form-field {
            width: 100%;
        }
    }
    `
  ]
})
export class NewsletterDialog {

  submitted = false;
	message = {
    text:'',
    subscribed: false
  };

  constructor(
    public dialogRef: MatDialogRef<NewsletterDialog>,
    @Inject(MAT_DIALOG_DATA) public data:any,
  private http: HttpClient,
  private mailchimpService:MailchimpService){
    //console.log(this.data)
  }

  newsletterFormGroup = new FormGroup({
    // reactive form components
    emailControl: new FormControl('', [
      Validators.required,
      Validators.email,
    ]),
    nameControl: new FormControl('', [
      Validators.required
    ]),
    phoneControl: new FormControl('', [])
  });

  onNoClick(): void {
    this.dialogRef.close();
  }
   

	submit() {

		if (
      this.newsletterFormGroup.controls.emailControl.status === 'VALID' && 
      this.newsletterFormGroup.controls.nameControl.status === 'VALID'){

      let bodyMailChimp = {
        email: this.newsletterFormGroup.controls.emailControl.value,
        fname: this.newsletterFormGroup.controls.nameControl.value,
        phone: this.newsletterFormGroup.controls.phoneControl.value
        
      }

      this.mailchimpService.addEmail(bodyMailChimp).subscribe(
        (response) => {
          console.log(response);
          if((response as any).contact_id != ''){
            this.message.text = 'Datele s-au trimis cu succes!';
            this.message.subscribed = true;
            setTimeout(() => {
              this.onNoClick()
            }, 2000);
          } else {
            this.message.text = 'Va rugam sa incercati cu alta adresa de e-mail!';
            this.message.subscribed = false;
          }
          
        },
        (err:any) => {
          console.log(err);
         // check error status code is 500, if so, do some action
         this.message.text = 'Va rugam sa incercati cu alta adresa de e-mail!';
         this.message.subscribed = false;
         this.newsletterFormGroup.controls.emailControl.reset();
        }
      );
    }
  }



}
