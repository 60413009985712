<!-- carousel -->
<div class="carousel bfd" [class.bfd] = "sizeSmall">
  <ng-container>
    <ng-container *ngFor="let slide of slides; let i = index">
      <img *ngIf="i === currentSlide" [src]="slide.src" alt="Banner Image" class="slide" @carouselAnimation  (click)="scroll(slide.href)" />
     
    </ng-container>
    <div class="controls">
      <button mat-icon-button (click)="onPreviousClick()" aria-label="Go Left">
        <mat-icon>chevron_left</mat-icon>
      </button>
      <button mat-icon-button (click)="onNextClick()" aria-label="Go Right">
        <mat-icon>chevron_right</mat-icon>
      </button>
    </div>
  </ng-container>
  </div>