
    <h1 mat-dialog-title>
        <button mat-icon-button aria-label="Close" (click)="closeDialog()">
            <mat-icon>close</mat-icon>
        </button>
        Cere Oferta
    </h1>
    <div mat-dialog-content>
        <div class="main-content">

            <div [formGroup]="OfferRequest" *ngIf="offerRequestId === ''">
                <mat-grid-list cols="6" [rowHeight]="'90'">
                    <mat-grid-tile [colspan]="sizeSmall ? '6' : '3'">
                        <mat-form-field appearance="outline">
                            <mat-label>Plecare din</mat-label>
                            <input matInput placeholder="Placeholder" formControlName="origin">
                            <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile [colspan]="sizeSmall ? '6' : '3'">
                        <mat-form-field appearance="outline">
                            <mat-label>Destinatia</mat-label>
                            <input matInput placeholder="Placeholder" formControlName="destination">
                            <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
                        </mat-form-field>
                    </mat-grid-tile>

                    <mat-grid-tile [colspan]="sizeSmall ? '6' : '3'">
                        <mat-form-field appearance="outline">
                            <mat-label>Prenume</mat-label>
                            <input matInput placeholder="Placeholder" formControlName="first_name">
                            <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile [colspan]="sizeSmall ? '6' : '3'">
                        <mat-form-field appearance="outline">
                            <mat-label>Nume de Familie</mat-label>
                            <input matInput placeholder="Placeholder" formControlName="last_name">
                            <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
                        </mat-form-field>
                    </mat-grid-tile>


                    <mat-grid-tile [colspan]="sizeSmall ? '6' : '3'">
                        <mat-form-field appearance="outline">
                            <mat-label>Adresa de Email</mat-label>
                            <input matInput placeholder="Placeholder" formControlName="email">
                            <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile [colspan]="sizeSmall ? '6' : '3'">
                        <mat-form-field appearance="outline">
                            <mat-label>Numar de Telefon</mat-label>
                            <input matInput placeholder="Placeholder" formControlName="phone">
                            <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
                        </mat-form-field>
                    </mat-grid-tile>

                    <mat-grid-tile [colspan]="sizeSmall ? '6' : '3'">
                        <mat-form-field appearance="outline">
                            <mat-label>Perioada Sejurului</mat-label>
                            <input matInput placeholder="Placeholder" formControlName="perioada">
                            <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
                            <mat-hint>Ex: 07.07.2024 - 14.07.2024</mat-hint>
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile [colspan]="sizeSmall ? '6' : '3'">
                        <mat-form-field appearance="outline">
                            <mat-label>Numar de persoane</mat-label>
                            <input matInput placeholder="Placeholder" formControlName="persoane">
                            <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
                            <mat-hint>Ex: 2AD + 1CH</mat-hint>
                        </mat-form-field>
                    </mat-grid-tile>

                    <mat-grid-tile [colspan]="sizeSmall ? '6' : '6'">
                        <button mat-raised-button class="custom-btn" aria-label="search Caută" (click)="send()">
                            <mat-icon style="font-size: 18px;line-height:22px;">send</mat-icon> Trimite Solicitarea
                        </button>
                    </mat-grid-tile>

                </mat-grid-list>
            </div>
            
            <div *ngIf="offerRequestId != ''">
                <p>Cerere trimisa cu succes!</p>
                <p>Un consultant te va contacta in cel mai scurt timp posibil!</p>
            </div>
        

        </div>
    </div>
