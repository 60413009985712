
<footer [class.XSmall] = "sizeSmall">
    <section class="foo-main">
        <section class="foo-1">
            <mat-grid-list cols="3" rowHeight="170px">
                <mat-grid-tile
                    *ngFor="let tile of tiles"
                    [colspan]="tile.cols"
                    [rowspan]="tile.rows">
                 
                    <ng-container *ngIf="tile.img === ''">
                        <h2>{{tile.title}}</h2>

                        <ng-container *ngFor="let btn of tile.links">

                            <button *ngIf="btn.router" mat-button class="nav-secondary-button"
                                [routerLink]="[ btn.link ]" routerLinkActive="active">
                                {{btn.text}}
                                <mat-icon>{{btn.icon}}</mat-icon>
                            </button>
                            <button *ngIf="!btn.router" mat-button class="nav-secondary-button"
                                (click)="openUrl(btn.link)">
                                {{btn.text}}
                                <mat-icon>{{btn.icon}}</mat-icon>
                            </button>
                        </ng-container>
                    </ng-container>
                </mat-grid-tile>
              </mat-grid-list>
        </section>
    </section>
    <section class="foo-second" >
        <section class="foo-2">
            <ng-container *ngFor="let btn of footerButtons">
                <button 
                mat-button
                class="footer-button"
                [routerLink]="[ btn.link ]" routerLinkActive="active">
                  {{btn.text}}
                  <mat-icon>{{btn.icon}}</mat-icon>
              </button>
            </ng-container>
        </section>
        <section class="foo-3">
            <div class="credit-card">
                <div class="ct-cards">
                    
                        <div>
                            <img alt="Stripe" src="assets/img/stripe-payments-600.webp" width="300" height="64" >
                        </div>
                    
                </div> 

                <div class="ct-anpc">
                    <a href="https://anpc.ro/ce-este-sal" rel="nofollow" target="_blank">
                        <img src="assets/img/sal.png" alt="SAL" width="200" height="49" style="max-width: 200px;">
                    </a> 
                    <a href="https://ec.europa.eu/consumers/odr" rel="nofollow" target="_blank">
                        <img src="assets/img/sol.png" alt="SOL" width="200" height="49" style="max-width: 200px;">
                    </a>
                </div>
                <div class="text">
                    <p>

                        Licenta turism nr. 2643 / 29.09.2022 <br>
                        Polita asigurare nr. 3837 / 21.09.2023 - Geroma. Limita de raspundere: 10.000,00 EUR<br>
                        Brevet turism nr. 8952 / 19.08.2004 - R. Petru - Stefan<br>
                        M.D.R.T. - 0800.86.82.82<br>

                    </p>

                    <p>TOURIST ADVISOR SRL<br>
                        CUI 40618710, Nr.Reg.Com. J26/497/2019
                    </p>
                </div>
            </div>
            <p class="copyright">Copyright © 2019 - {{year}} Tourist Advisor - Toate drepturile rezervate.</p>
        </section>
    </section>
    <ng-container>
        <button mat-button class="whatsapp-button" (click)="openWhatsApp()">
            <svg class="whatsapp-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
              <path d="M16 .3A15.7 15.7 0 0 0 .3 16a15.53 15.53 0 0 0 2.2 8L.1 31.7a1.4 1.4 0 0 0 1.9 1.9L8 29.5A15.53 15.53 0 0 0 16 31.7 15.7 15.7 0 0 0 31.7 16 15.7 15.7 0 0 0 16 .3zm0 29.4a13.56 13.56 0 0 1-7.3-2.1l-.5-.3-5.4 1.5 1.5-5.4-.3-.5a13.59 13.59 0 0 1 17.4-19.4 13.56 13.56 0 0 1 9.4 13.4A13.67 13.67 0 0 1 16 29.7zm7.5-10.5c-.4-.2-2.5-1.2-2.9-1.3s-1-.2-1.4.2-1.6 1.6-2 1.9-.7.3-1.2-.1a10.53 10.53 0 0 1-3.1-2.8 9.17 9.17 0 0 1-2-3.1c-.2-.4 0-.8.1-1.1.1-.2.2-.3.3-.5s.3-.3.4-.4.2-.2.3-.3c.1-.2.2-.4.2-.6s-.1-.5-.2-.7l-1.4-2.2c-.3-.4-.7-.5-1-.5h-.6a1.42 1.42 0 0 0-1 .5c-.3.3-1 1-1 2.5s1 2.9 1.1 3.1a17.75 17.75 0 0 0 3.7 5.1A17.55 17.55 0 0 0 18 23.5c1.5 0 2.2-.9 2.5-1.2s.4-.7.5-1.1.2-.8 0-1c-.1-.2-.4-.3-.8-.5z"/>
            </svg>
          </button>
    </ng-container>
</footer>