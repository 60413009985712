<!-- carousel -->
 <div class="carousel" [class.mobile] = "sizeSmall">
    <ng-container *ngFor="let slide of slides; let i = index">
      <img
        *ngIf="i === currentSlide" 
        alt="Desktop Banner" 
        [src]="slide.src"
        class="slide"
      />

      <!-- <div class="beach-element" *ngIf="sizeSmall">
        <div class="sand"></div>
        <div class="wet-sand"></div>
        <div class="sea">
          <div class="seafoam"></div>
        </div>
        <div class="sand-front"></div>
     </div> -->

      <div class="carousell-box">
        <div class="content-box" 
            *ngIf="i === currentSlide">
           
           <div class="text">
            <h1 *ngIf="slide.h1 && slide.h1 != ''" [innerHTML]="slide.h1"></h1>
            <h2 *ngIf="slide.h2 && slide.h2 != ''" [innerHTML]="slide.h2"></h2>
            <h2 *ngIf="slide.h21 && slide.h21 != ''" [innerHTML]="slide.h21"></h2>
            <p *ngIf="slide.text && slide.text != ''" [innerHTML]="slide.text"></p>
            <div class="buttons" *ngIf="i === currentSlide && slide.link != ''" [ngClass]="{'adjust-mobile': sizeSmall}">
              <button mat-raised-button class="custom-btn" [attr.aria-label]="slide.buttonText" (click)="goToBlog()">{{slide.buttonText}}</button>
            </div>
           </div> 
          
        </div>
      </div>
      
      <div class="controls" *ngIf="i === currentSlide && !sizeSmall">
        <button mat-icon-button (click)="onPreviousClick()" aria-label="Go Left">
          <mat-icon>chevron_left</mat-icon>
        </button>
        <button mat-icon-button (click)="onNextClick()" aria-label="Go Right">
          <mat-icon>chevron_right</mat-icon>
        </button>
      </div>
      
    </ng-container>
   
  </div>
  