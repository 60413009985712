export const environment = {
  firebase: {
    projectId: 'tourist-advisor-agency',
    appId: '1:326900670461:web:e3856f59f97f47df3b8394',
    storageBucket: 'tourist-advisor-agency.appspot.com',
    locationId: 'europe-west',
    apiKey: 'AIzaSyCrgBcUg6AnUuFF5nB6uL5br3Mty-1P8B4',
    authDomain: 'tourist-advisor-agency.firebaseapp.com',
    messagingSenderId: '326900670461',
    measurementId: 'G-8XR6CBTTVE',
  },
  production: true,
  api: {
    baseUrl: "https://us-central1-tourist-advisor-agency.cloudfunctions.net"
  }
};
