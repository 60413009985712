import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { trigger, transition, useAnimation } from "@angular/animations";
import { fadeIn, fadeOut,} from "./banners.animations";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Router } from "@angular/router";

@Component({
  selector: "banners",
  templateUrl: "./banners.component.html",
  styleUrls: ["./banners.component.scss"],
  animations: [
    trigger("carouselAnimation", [
      transition("void => *", [useAnimation(fadeIn, {params: { time: '200ms' }})]),
      transition("* => void", [useAnimation(fadeOut, {params: { time: '200ms' }})]),
    ])
  ]
})

export class BannersComponent implements OnInit{
  sizeSmall = false;
  @Input() slides: any;

  constructor(private responsive: BreakpointObserver, private router: Router) {}

  currentSlide = 0;

  scroll(href: string) {
    if(href != ""){
      if(href === 'scrollTop'){
        window.scroll({top: 0,left: 0,behavior: 'smooth'});
      } else {
        this.router.navigate([`/${href}`]);
      }
    }
  }

  ngOnInit(): void {
    this.responsive.observe([Breakpoints.XSmall,Breakpoints.Small])
    .subscribe(result => {
      this.sizeSmall = result.matches;
    });
  }

  onPreviousClick() {
    const previous = this.currentSlide - 1;
    this.currentSlide = previous < 0 ? this.slides.length - 1 : previous;
    console.log("previous clicked, new current slide is: ", this.currentSlide);
  }

  onNextClick() {
    const next = this.currentSlide + 1;
    this.currentSlide = next === this.slides.length ? 0 : next;
    console.log("next clicked, new current slide is: ", this.currentSlide);
  }
}
