<main [class.XSmall] = "sizeSmall">
    
    <section class="container">
      <carousel [slides]="carousell | async"></carousel>

      <div class="selection-content">
        <div class="left-box">
          <div class="title">
            <p style=" text-align: left; margin-left: 10px; ">
              <mat-icon style="font-size: 20px;line-height:33px;">mode_of_travel</mat-icon> 
              Planifică-ți Călătoria
            </p>
          </div>

          <div class="white-box">

             <mat-grid-list cols="6" [rowHeight]="sizeSmall ? '65px' : '130px'" >

              <mat-grid-tile [colspan]="sizeSmall ? '6' : '2'" class="pushDownPlan">
                <div class="sel-origin-destination">

                  <div [formGroup]="DepartureSelect" style="float: left;width: 200px;" class="input-separator departure-arrival">
                    <mat-label *ngIf="origin.name != ''">Plecare din</mat-label>
                    <mat-select 
                    placeholder="Plecare din"
                    (selectionChange)="changeDeparture($event)"
                    disableOptionCentering 
                    class="myDeparture" 
                    panelClass="myPanelClass departure" 
                    formControlName="name">
                      <mat-select-trigger>
                        {{ DepartureSelect.controls['name'].value?.name }}
                      </mat-select-trigger>
                      <mat-option *ngFor="let item of departures | async" [value]="item">
                        <div class="country">
                          <span>{{item.country}}</span>
                        </div>
                        <div class="name">
                          <span class="left">{{item.name}}</span>
                          <span class="right">{{item.call}}</span>
                        </div>
                      </mat-option>
                    </mat-select>
                  </div>
    
    
    
                  <div [formGroup]="ArrivalSelect" style="float: left;width: 200px;" class="departure-arrival">
                    <mat-label *ngIf="destination.name != ''">Desținatia</mat-label>
                    <mat-select 
                    placeholder="Destinația" 
                    (selectionChange)="changeArrival($event)"
                    (click)="destinationClickEvent(arrivals)"
                    disableOptionCentering 
                    class="myArrival" 
                    panelClass="myPanelClass arrival" 
                    formControlName="name">
                      <mat-select-trigger>
                        
                        {{ ArrivalSelect.controls['name'].value?.name }}
                      </mat-select-trigger>
                      <mat-option *ngFor="let item of arrivals | async" [value]="item">
                        <div class="country">
                          <span>{{item.country}}</span>
                        </div>
                        <div class="name">
                          <span class="left">{{item.name}}</span>
                          <span class="right">{{item.call}}</span>
                        </div>
                      </mat-option>
                    </mat-select>
                  </div>
    
                </div>
              </mat-grid-tile>

              <mat-grid-tile [colspan]="sizeSmall ? '6' : '2'" class="tileSelectPeriod">
                <div class="sel-origin-destination w91">
                  <div [formGroup]="PeriodSelect">
                    <mat-label *ngIf="period.start != ''" class="range-label">
                      <span class="left">Plecare</span>
                      <span class="right">Întoarcere</span>
                    </mat-label>
                    <mat-select 
                    placeholder="Selectează Perioada Sejurului" 
                    (selectionChange)="changePeriod($event)" 
                    (click)="vacationPeriodClickEvent(periods)"
                    disableOptionCentering 
                    class="myPeriod" 
                    panelClass="myPanelClass period" 
                    formControlName="start">
                      <mat-select-trigger>
                        {{ returnDate(PeriodSelect.controls['start'].value?.start) }} - {{returnDate(PeriodSelect.controls['start'].value?.end)}}
                      </mat-select-trigger>
                      <ng-container *ngFor="let item of periods | async; let i = index">
                        <mat-option *ngIf="item.active" [value]="item" [attr.data-index]="i">
                          <div class="country">
                            <span>Perioada Sejurului</span>
                          </div>
                          <div class="name">
                            <span class="left">{{returnDate(item.start)}}</span>
                            <span class="right">{{returnDate(item.end)}}</span>
                          </div>
                        </mat-option>
                      </ng-container>
                    </mat-select>

                  </div>
                </div>
              </mat-grid-tile>

              <mat-grid-tile  [colspan]="sizeSmall ? '6' : '1'" class="tileSelectPpl">
                <div class="people" (click)="openPeopleSelector()">
                  <span class="label">Persoane</span>
                  <button mat-button [attr.aria-label]="returnPeopleSelected()">
                    <span class="adults" *ngIf="people.adults > 0"><span class="number">{{people.adults}}</span><span class="ppl">{{people.adults > 1 ? "adulți" : "adult"}}</span></span>
                    <span class="adults" *ngIf="people.children > 0"><span class="number"> {{people.children}}</span><span class="ppl">{{people.children > 1 ? "copii" : "copil"}}</span></span>
                    <span class="adults" *ngIf="people.infants > 0"><span class="number"> {{people.infants}}</span><span class="ppl">{{people.infants > 1 ? "nou născuți" : "nou născut"}}</span></span>
                  </button>
                </div>
              </mat-grid-tile>

              <mat-grid-tile [colspan]="sizeSmall ? '6' : '1'">
                <button mat-raised-button class="custom-btn" aria-label="search Caută" 
                (click)="openOffer()">
                <mat-icon style="font-size: 18px;line-height:22px;">search</mat-icon> {{origin.call != '' && destination.call != '' && period.start != '' ? "Caută Acum" : "Caută"}}
              </button>
              </mat-grid-tile>
  

            </mat-grid-list>

          </div>

        </div>
      </div>
      

    </section>
   
    <section class="main-section">

      <div class="full-width" *ngIf="!sizeSmall">
        <div class="banner">
          <p class="title" >
            Oportunități de călătorie cu Tourist Advisor!
          </p>
          <mat-grid-list [cols]="sizeSmall ? '2' : '8'" [rowHeight]="sizeSmall ? '100px' : '64px'">
            <mat-grid-tile colspan="2" class="b-item">
              <div class="left">
                <mat-icon>not_listed_location</mat-icon> 
              </div>
              <div class="right">
                <p class="title">Cautare Sejur</p>
                <p class="text">Esti flexibil cu datele? Găsești cel la mai mic tarif disponibil pentru călătoria ta și rezervă la cel mai bun preț.</p>
              </div>
            </mat-grid-tile>
            <mat-grid-tile colspan="2" class="b-item">
              <div class="left">
                <mat-icon>fmd_bad</mat-icon> 
              </div>
              <div class="right">
                <p class="title">Nu Rata Ofertele Noastre</p>
                <p class="text">Abonează-te la newsletter-ul nostru și fii primul care află despre noi destinatii și oferte speciale.</p>
              </div>
            </mat-grid-tile>
            <mat-grid-tile colspan="2" class="b-item">
              <div class="left">
                <mat-icon>wrong_location</mat-icon> 
              </div>
              <div class="right">
                <p class="title">Condtii de Calatorie</p>
                <p class="text">Găsești toate informațiile necesare cu privire la condițiile de călătorie impuse de autorități și alte informații relevante.</p>
              </div>
            </mat-grid-tile>
            <mat-grid-tile colspan="2" class="b-item">
              <div class="left">
                <mat-icon>where_to_vote</mat-icon> 
              </div>
              <div class="right">
                <p class="title">Sfaturi de Calatorie</p>
                <p class="text">Iți suntem alături pe tot parcursul călătoriei tale, oferindu-ți informațiile necesare derulării in foarte bune condiții a sejurului tău.</p>
              </div>
            </mat-grid-tile>
          </mat-grid-list>
        </div>
      </div>

   <div class="page-content" *ngIf="!sizeSmall" style="min-height: 500px;">
    <banners [slides]="banners | async"></banners>
   </div>
   



        <div class="page-content" style="margin-top: 2em;">

          <div class="menorca">

            <p class="title">Descoperă Insula Menorca</p>

            <div class="insta-feeed" *ngIf="sizeSmall">
              <figure data-behold-id="AYXrvhClbKu51aJtcqhw"></figure>
              <div customScript>
                  <div src="https://w.behold.so/widget.js" type="module"></div>
              </div>
            </div>

            <div class="insta-feeed" *ngIf="!sizeSmall">
              <figure data-behold-id="Qqmmbflt6mhKOqeU4G4I"></figure>
              <div customScript>
                  <div src="https://w.behold.so/widget.js" type="module"></div>
              </div>
            </div>

            <!-- <mat-grid-list [cols]="sizeSmall ? '1' : '3'" [rowHeight]="sizeSmall ? '280px' : '320px'" class="menorca">

              <mat-grid-tile class="men-item">
                <mat-card>
                  <div class="img-plc">
                    <img mat-card-image  width="481" height="200" 
                          src="https://firebasestorage.googleapis.com/v0/b/tourist-advisor-agency.appspot.com/o/home-menorca%2Fcalagaldana-600.webp?alt=media&token=d3a062ac-e07f-4dd2-8b80-3a2b64010374" 
                          alt="Cala Galdana, Insulele Bealeare, Menorca">
                  </div>
                  <div class="contet">
                      <p class="title">Cala Galdana</p>
                      <p class="sub-title">Insulele Bealeare, Menorca</p>
                      <button mat-raised-button routerLink="/destinations/visit/menorca" aria-label="Detalii">Detalii</button>
                  </div>
                </mat-card>
              </mat-grid-tile>
              <mat-grid-tile class="men-item">
                <mat-card>
                  <div class="img-plc">
                    <img mat-card-image  width="481" height="200" src="https://firebasestorage.googleapis.com/v0/b/tourist-advisor-agency.appspot.com/o/home-menorca%2Fpestera-600.webp?alt=media&token=082e3db5-feb8-446a-9520-7597cf9e94c7" 
                    alt="Secret Cova, Insulele Bealeare, Menorca">
                  </div>
                  <div class="contet">
                      <p class="title">Secret Cova</p>
                      <p class="sub-title">Insulele Bealeare, Menorca</p>
                      <button mat-raised-button routerLink="/destinations/visit/menorca" aria-label="Detalii">Detalii</button>
                  </div>
                </mat-card>
              </mat-grid-tile>
              <mat-grid-tile class="men-item">
                <mat-card>
                  <div class="img-plc">
                    <img mat-card-image  width="481" height="200" src="https://firebasestorage.googleapis.com/v0/b/tourist-advisor-agency.appspot.com/o/home-menorca%2Fciutadela-600.webp?alt=media&token=0cff71ac-37b3-4111-872a-5c3a744eb088" 
                    alt="Ciutadela, Insulele Bealeare, Menorca">
                  </div>
                  <div class="contet">
                      <p class="title">Ciutadela</p>
                      <p class="sub-title">Insulele Bealeare, Menorca</p>
                      <button mat-raised-button routerLink="/destinations/visit/menorca" aria-label="Detalii">Detalii</button>
                  </div>
                </mat-card>
              </mat-grid-tile>
  
            </mat-grid-list>
           
            <mat-grid-list *ngIf="!sizeSmall" [cols]="sizeSmall ? '1' : '2'" [rowHeight]="sizeSmall ? '280px' : '420px'" class="menorca">

              <mat-grid-tile class="men-item">
                <mat-card>
                  <div class="img-plc">
                    <img mat-card-image width="718" height="200" src="https://firebasestorage.googleapis.com/v0/b/tourist-advisor-agency.appspot.com/o/home-menorca%2Fmacarella.webp?alt=media&token=2fa2813e-911e-4a33-8fc9-bad39aaf030f" 
                    alt="Cala Macarella, Insulele Bealeare, Menorca">
                  </div>
                  <div class="contet">
                      <p class="title">Cala Macarella</p>
                      <p class="sub-title">Insulele Bealeare, Menorca</p>
                      <button mat-raised-button routerLink="/destinations/visit/menorca" aria-label="Detalii">Detalii</button>
                  </div>
                </mat-card>
              </mat-grid-tile>

              <mat-grid-tile class="men-item">
                <mat-card>
                  <div class="img-plc">
                    <img mat-card-image width="718" height="200" src="https://firebasestorage.googleapis.com/v0/b/tourist-advisor-agency.appspot.com/o/home-menorca%2Fmenorca-stone.webp?alt=media&token=c7e42ae0-40b2-4c3e-a773-2323ea733936" 
                    alt="Torre d’en Galmés, Insulele Bealeare, Menorca">
                  </div>
                  <div class="contet">
                      <p class="title">Torre d’en Galmés</p>
                      <p class="sub-title">Insulele Bealeare, Menorca</p>
                      <button mat-raised-button routerLink="/destinations/visit/menorca" aria-label="Detalii">Detalii</button>
                  </div>
                </mat-card>
              </mat-grid-tile>
  
            </mat-grid-list> -->
  
          </div>


        </div>

        <div class="full-width-ns">
          
          <div class="news-letter" (click)="openNewsletter()">
            <p>Abonează-te la <strong>newsletter</strong> <br> descoperă ofertele noastre speciale</p>
          </div>
        </div>

    </section>
</main>
